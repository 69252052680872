.storeItem2 {

    height: 100px;
    width: 100%;

    display: flex;
    justify-content: left;
    align-items: center;
}

.storeItem2-mobile {}

.storeItem2-pc {}



.storeItem2-box {
    flex-grow: 1;
}

.storeItem2-txt {
    display: flex;
    align-items: center;

    margin-left: 30px;

    font-size: 15pt;

    width: 200px;
    height: 50px;

}

.storeItem2-price {
    width: 160px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.storeItem2-img {
    width: 80px;
    height: 80px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.storeItem2-price-txt {
    font-size: 18pt;
}

.storeItem2-btn {
    width: 200px;
    height: 50px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    color: white;
    background: rgb(108, 23, 58);
    background: linear-gradient(0deg, rgba(108, 23, 58, 1) 0%, rgba(230, 0, 104, 1) 100%);
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 0.1s;
}

.storeItem2-btn:active {
    transform: scale(0.95);
}

.storeItem2-btn2 {
    width: 200px;
    height: 50px;

    margin-right: 20px;

    display: flex;
    align-items: center;
    justify-content: center;


}


.store-list {
    width: 80%;
}

.storeItem2-price-img {
    width: 50px;
    height: 50px;
    background-image: url(./images/apple.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    margin-right: 20px;
}

.storeItem2-price-img-i0 {
    background-image: url(./images/feeder.png);
}

.storeItem2-price-img-i1 {
    background-image: url(./images/birdhouse.png);
}

.storeItem2-price-img-i2 {
    background-image: url(./images/nest.png);
}

.storeItem2-price-img-i3 {
    background-image: url(./images/birdie.png);
}

.storeItem2-price-img-i4 {
    background-image: url(./images/cat2.png);
}

.storeItem2-price-img-i5 {
    background-image: url(./images/lamp.png);
}

.storeItem2-price-img-i6 {
    background-image: url(./images/dwarf.png);
}

.storeItem2-price-img-i7 {
    background-image: url(./images/swing.png);
}

.storeItem2-price-img-i8 {
    background-image: url(./images/fountain.png);
}

.storeItem2-price-img-i9 {
    background-image: url(./images/alcove.png);
}

.storeItem2-price-img-i10 {
    background-image: url(./images/flowerbed.png);
}

.storeItem2-line {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    height: 1px;
    background-color: #80AD3A;
}

.storeItem2-active {
    opacity: 1;
    pointer-events: auto;
}

.storeItem2-noActive {
    opacity: .5;
    pointer-events: none;
}

/*---mobile---*/

.storeItem2-btn-m {
    width: 180px;
    height: 50px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    color: white;
    background: rgb(108, 23, 58);
    background: linear-gradient(0deg, rgba(108, 23, 58, 1) 0%, rgba(230, 0, 104, 1) 100%);
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 0.1s;
}

.storeItem2-btn2-m {
    width: 200px;
    height: 50px;

    margin-left: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.storeItem2-price-img-m {
    width: 25px;
    height: 25px;
    background-image: url(./images/apple.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    margin-right: 20px;
}
.rating-container {
    color: #005426;
    font-family: 'Days One';
    flex-direction: column;
}

.rating {
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
}

.rating-head-btns-gap {
    width: 20px;
    /* background-color:red;*/
    margin-bottom: 20px;
}

.rating-head-line {
    display: flex;
    width: 820px;
    height: 2px;
    /*background-color:rgb(8, 0, 226);*/
    margin-bottom: 20px;
}

.rating-head-line-item {
    width: 400px;
    height: 2px;
    background-color: #005426;
}

.rating-head-line-item0 {
    height: 2px;
    /*background-color: rgb(227, 230, 15);*/
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.rating-pc {
    font-size: 36px;
}

.rating-mobile {
    font-size: 22px;
    margin-top: 20px;
}

.rating-list {
    /*display: flex;*/
    /*justify-content: center; */
    /* align-items: center;*/
    color: #005426;
    font-family: 'Days One';
    flex-direction: column;
    width: 100%;

    height: 600px;
    min-height: 320px;
    overflow-y: auto;

  /*  scrollbar-width: auto;
    scrollbar-color: #005426 #ffffff; */
}

/* Chrome, Edge, and Safari */

.rating-list::-webkit-scrollbar {
    width: 12px;
}
.rating-list::-webkit-scrollbar-track {
    background: #BFED64;
    border-radius: 10px;
}
.rating-list::-webkit-scrollbar-thumb {
    background-color: #005426;
    border-radius: 10px;
    border: none;
}


.rbtn {
    width: 400px;
    text-align: center;
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 0.1s;
}

.rbtn:active {
    transform: scale(0.95);
}

.rbtn-active {
    color: #005426;
    pointer-events: none;
}

.rbtn-passive {
    color: white;
    pointer-events: auto;
}

.rating-head-txt {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
    font-size: 24px;
}

.rating0 {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
}

/*---mobile---*/

.rating-head-line-m {
    display: flex;
    width: 540px;
    height: 2px;
    /*background-color:rgb(8, 0, 226);*/
    margin-bottom: 20px;
}

.rating-head-line-item-m {
    width: 270px;
    height: 2px;
    background-color: #005426;
}

.rating-head-line-item0-m {
    height: 2px;
    /*background-color: rgb(227, 230, 15);*/
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.rating-list-m {

    color: #005426;
    font-family: 'Days One';
    flex-direction: column;
    width: 100%;

    overflow-y: auto;

    scrollbar-width: auto;
    scrollbar-color: #005426 #ffffff;
}
.c3-scene {
    width: 320px;
    height: 480px;  
    perspective: 600px;
    border-radius: 15px;   
    -webkit-tap-highlight-color: transparent;
  }

.c3-card {
    width: 100%;
    height: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;
    cursor: pointer;
    position: relative;    
  }
  
  .c3-card.is-flipped {
    transform: rotateY(-180deg);
  }
  
  .c3-card__face {
    position: absolute;
    width: 100%;
    height: 100%;    
    
    text-align: left;   
   
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    font-size: 18px;   
    color: #005426;
    font-family: 'Days One';

    -webkit-box-shadow: 0px 0px 8px 7px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 8px 7px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 8px 7px rgba(34, 60, 80, 0.2);
  }
  
  .c3-card__face--front {
    background-color: white;
    border-radius: 15px;
  }
  
  .c3-card__face--back {
    background-color: white;
    border-radius: 15px;
    transform: rotateY(180deg);
  }

  .c3-txt-cont{
    display: flex;
    width:100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }

  .c3-txt-cont2{
   
    width:90%;  
    justify-content: center;
    align-items: center;
  }

  .c3-txt-btn{
    position: relative;
    width: 100%;
    text-align: center;
    top:-30px;
  }
.ScaleDiv {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    transform-origin: left top;
}

.ScaleDiv-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
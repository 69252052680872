.WebSocket{
    position: absolute;
    z-index: 99999;
    top: 90px;
    left: 10px;
    width: 170px;
    height: 70px;
    background-color: azure;
    border-radius: 10px;
    padding: 5px;

   /* display: flex;
    justify-content: center;
    align-items: center;*/
}
.marginRegBtnNP-pc {
    margin-top: 20px;
}

.marginRegBtnNP-mobile {
    margin-top: 0px;
}

.marginRegBtnNP2 {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}


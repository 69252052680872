.prTxt2-pc {
    font-family: 'Days One';
    color: white;
    font-size: 19pt;
    margin-top: 20px;
    margin-bottom: 40px;
}

.prTxt2-mobile {
    font-family: 'Days One';
    color: white;
    font-size: 19pt;
    margin-top: 50px;
    margin-bottom: 20px;
}

.marginPrBtn-pc {
    display: flex;
    margin-top: 50px;
    align-items: center;
    flex-direction: column-reverse;
}

.marginPrBtn-mobile {
    display: flex;
    align-items: center;
    margin-top: 10px;
    flex-direction: column-reverse;
}

.pGap-pc {
    height: 20px;
}

.pGap-mobile {
    height: 20px;
}

.prPop-txt1 {
    font-family: 'Days One';
    color: white;
    font-size: 16pt;
    margin-top: 25px;
    margin-bottom: 20px;
    text-align: center;
}

.prPop-txt2 {
    font-family: 'Days One';
    color: white;
    font-size: 12pt;   
    margin-bottom: 67px;
    text-align: center;
}


.prPop {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 450px;
}

.prPopVT {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.prPopVF {
    display: none;
}
.userCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userCard_Bottom {
    width: 100%;
    height: 1px;
    background-color: #e0e0e0;
}

.userCard_cont {
    width: 420px;
    height: 60px;

    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
}


.userCard_cssDisable {
    pointer-events: none;
    opacity: 0.5;
}

.userCard_photo {
    width: 60px;
    height: 60px;
    background-image: url(./images/uicon.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.userCard_txt {
    margin-left: 10px;
}

.userCard_txt1 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.userCard_txt2 {}
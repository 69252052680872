.store-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #005426;
    font-family: 'Days One';
    width: 100%;
    user-select: none;  
}

.store {
    margin-top: 92px;
    width: 98%;
    max-width: 1460px;
   
    /*background-color: aqua;*/
    /*
    -webkit-box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.2);
    */
}

.score_tree_score {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.score_tree_txt {
    font-size: 20px;
}

.score_tree_money{
    font-size: 20px;
    margin-left: 20px;
}

.score_tree_icon {
    margin-left: 20px;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    background-image: url(./images/apple.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.store-header {
    display: flex;
}

.store-header-title {
    display: flex;
    height: 70px;
    flex: auto;

    align-items: center;
    justify-content: center;   
    
    border-radius: 15px 15px 0 0;
    
    user-select: none;
    cursor: pointer;

    text-align: center;

    -webkit-tap-highlight-color: transparent;
}

.store-header-title-pc{
    font-size: 20px;
}

.store-header-title-mobile{
    font-size: 18px;
}

.store-header-title-active {
    background-color: #bfed64;
    position: relative;
    z-index: 950;
    box-shadow: 0 0px 2.5px 6px rgba(0, 0, 0, 0.05);
    color: #005426;

}

.store-header-title-passive {
    color: white;
    background-color: #92CB5B;
}

.store-body {
    position: relative;
    z-index: 900;

    background-color: #bfed64;
    width: 100%;
    max-width: 1460px; 
    box-shadow: 0 0 2.5px 6px rgba(0, 0, 0, 0.05);
    border-radius: 0 0 7px 15px;
}

.store-body-line {
    background-color: #bfed64;
    width: 100%;
    height: 10px;
    position: relative;
    z-index: 1100;
}

.store-body-content {
    padding: 20px;
}

.store-body-content{
    overflow-y: scroll;
    height: 100%;
    border-radius: 0 0 15px 15px;


}

/*
::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 15px;
}

::-webkit-scrollbar-thumb {
    background:#005426;
    border-radius: 15px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
*/

.store-body-content-empty{
    width: 100%;   

    font-size: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
}
#intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.intro-logo {
    background-image: url(./images/logo.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.intro-logo-pc {
    width: 455px;
    height: 108px;
}

.intro-logo-mobile {
    width: 424px;
    height: 100px;
}

.intro-img {
    background-image: url(./images/tree.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.intro-img-pc {
    width: 200px;
    height: 227px;
    margin-top: 16px;
    margin-bottom: 40px;
}

.intro-img-mobile {
    width: 159px;
    height: 183px;
    margin-top: 70px;
    margin-bottom: 80px;
}

.marginBtn {
    margin-bottom: 40px;
}
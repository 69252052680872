#nickname {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.nickname-logo {
    background-image: url(../Intro/images/logo.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.nickname-logo-pc {
    width: 456px;
    height: 108px;
}

.nickname-logo-mobile {
    width: 424px;
    height: 100px;
}

.nickname-img {
    background-image: url(./images/noicon.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.nickname-img-pc {
    width: 104px;
    height: 128px;
    /* margin-top: 24px; */
    margin-bottom: 32px;
}

.nickname-img-mobile {
    width: 159px;
    height: 183px;
    margin-top: 16px;
    margin-bottom: 55px;
}

.nickname-text {

    color: #005426;
    font-family: 'Days One';
    margin-bottom: 24px;
    text-transform: uppercase;
}

.nickname-text-pc {
    font-size: 19pt;
}

.nickname-text-mobile {
    font-size: 12pt;
}

.nickname-btnDelimiter {
    height: 32px;
}

.nickname-btns-cont {
    margin-top: 16px;
    display: flex;
    gap: 1rem;
}

.nickname-btns-cont {
   /* height: 2px;*/
}
.App-pc {
  position: absolute;
  background: rgb(198, 234, 116);
  background: radial-gradient(circle, rgba(198, 234, 116, 1) 0%, rgba(99, 175, 71, 1) 100%);
  min-width: 1000px;
  width: 100%;
  min-height: 100vh;
  display: grid;  
}

.App-mobile {
  position: absolute;
  background: rgb(198, 234, 116);
  background: radial-gradient(circle, rgba(198, 234, 116, 1) 0%, rgba(99, 175, 71, 1) 100%);
  min-width: 540px;
  width: 100%;
  min-height: 100vh;
  display: grid;
}

.dialog-cont {
  width: 100%;
  height: 100%;

  min-height: 460px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  justify-content: center;
  font-family: 'Days One';
  user-select: none;
}

.dialog-cont-name {
  font-size: 20px;
  text-align: center;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  color: #005426;  
}

.dialog-cont-dis {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  font-size: 16px;
  text-align: center;
  font-family: 'Roboto';
}

.dialog-cont-btn {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.dialog-cont-pic {
  width: 200px;
  height: 200px;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: url(../public/himages/wintree.png);
}

.App-bg-cont-pc{
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
 
  pointer-events: none;

  background: rgb(198, 234, 116);
  background: radial-gradient(circle, rgba(198, 234, 116, 1) 0%, rgba(99, 175, 71, 1) 100%);

  z-index: 0;
}

.App-bg-cont-grass{
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: url(../public/himages/grass.png);
}

.App-bg-content{
  z-index: 100;
}

#background_tree_pic1-pc {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(../public/himages/t1.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
  width: 10%;
  height: 50%;
}

#background_tree_pic2-pc {
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: url(../public/himages/t2.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;
  width: 10%;
  height: 50%;
}

#background_tree_pic3-pc {
  position: absolute;
  top: 0;
  right: 0;
  width: 10%;
  height: 50%;			
  background-image: url(../public/himages/t3.png);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;
}

#background_tree_pic4-pc {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10%;
  height: 50%;			
  background-image: url(../public/himages/t4.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
}

.dialog-cont-btn-space{
  width: 20px;
}

#background_tree_pic1-mobile {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(../public/himages/t1.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
  width: 15%;
  height: 50%;
}

#background_tree_pic2-mobile {
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: url(../public/himages/t2.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;
  width: 15%;
  height: 50%;
}

#background_tree_pic3-mobile {
  position: absolute;
  top: 0;
  right: 0;
  width: 15%;
  height: 50%;			
  background-image: url(../public/himages/t3.png);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;
}

#background_tree_pic4-mobile {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 15%;
  height: 50%;			
  background-image: url(../public/himages/t4.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
}
.profile_nickname {
    font-size: 40px;
}

.profile_score {
    font-size: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.profile_level {
    font-size: 30px;
}

.profile_btn_container {
    width: 100%;
    display: flex;
}

.profile_tree {
    width: 600px;
    height: 420px;
    background-color: aqua;
    background-image: url(../Game/images/tree10.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 30%;

}

.profile_btn {
    margin-left: 15px;
    margin-top: 62px;
    border-radius: 50px;
    width: 670px;
    height: 90px;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 26pt;
    text-transform: uppercase;

}

.profile_btn_icon_1 {
    background-image: url(./images/b1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 30px;
    height: 36px;
    margin-left: 30px;
    margin-right: 20px;
}

.profile_btn_icon_2 {
    background-image: url(./images/b2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 21px;
    height: 36px;
    margin-left: 30px;
    margin-right: 20px;
}

.greenBtnIcon {
    background: rgb(23, 108, 41);
    background: linear-gradient(0deg, rgba(23, 108, 41, 1) 0%, rgba(103, 230, 0, 1) 100%);
    font-family: 'Days One';
    color: white;
}

.profile_tree_score_cont {
    position: relative;
    width: 80%;
    max-width: 1100px;
    margin-top: 100px;
}

.profile_tree_score {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: right;
    align-items: center;
}

.profile_tree_txt {
    font-size: 21pt;
    margin-left: 46px;
    margin-top: 18px;
    text-align: center;
}

.profile_tree_icon1 {
    width: 168px;
    height: 58px;
    background-image: url(./images/applewin1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.profile_tree_icon2 {
    width: 168px;
    height: 58px;
    background-image: url(./images/applewin2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.profile_tree_icon3 {
    width: 168px;
    height: 58px;
    background-image: url(./images/applewin3.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.profile_tree_icon4 {
    width: 168px;
    height: 58px;
    background-image: url(./images/applewin4.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.profile_tree_icon5 {
    width: 168px;
    height: 58px;
    background-image: url(./images/applewin5.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.profile_tree_icon6 {
    width: 168px;
    height: 58px;
    background-image: url(./images/applewin6.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.profile_test {
    width: 1503px;
    height: 990px;
}

.testImg {
    content: url(./images/pic1.png);
    position: absolute;
}

.fireFox {
    transform-origin: "left top";
}

.profile_container {
    display: flex;
    flex-flow: column;

    /*width: 100vw;*/

    justify-content: center;
    align-items: center;

    width: fit-content;
    height: fit-content;


    color: #005426;
    font-family: 'Days One';
    user-select: none;
}


.profile-content {
    width: 1503px;
    height: 990px;
}

.profile-content-sep-0 {
    height: 10px;
}

.profile-content-sep-1 {
    height: 20px;
}

.profile-content-sep-2 {
    width: 116px
}

.profile-content-txt {
    width: 100%;
    text-align: center;
}


/*---mobile----*/

.profile-m {
    width: 520px;
    margin: 0 auto;


}

.profile_nickname-m {
    font-size: 35px;
}

.profile_score-m {
    font-size: 25px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.profile_level-m {
    font-size: 25px;
}

.profile_btn_container-m {
    width: 100%;
    display: flex;
    flex-direction: column;

    margin-top: 60px;
}

.profile-content-sep-2-m {
    height: 20px;
}

.profile_btn-m {
    border-radius: 50px;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 19pt;
    text-transform: uppercase;
}

.profile_container-m {
    display: flex;
    flex-flow: column;

    width: 100%;


    justify-content: center;
    align-items: center;

    height: 100%;

    color: #005426;
    font-family: 'Days One';
    user-select: none;
}

.profile_btn_off {
    opacity: .5;
    pointer-events: none;
}

.profile_healthUp {
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 0.1s;
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
}

.profile_healthUp:active {
    transform: scale(0.99);
}

.profile_healthUp_u {
    text-decoration: underline;
}
.store2-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #005426;
    font-family: 'Days One';
    width: 100%;
    user-select: none;
}

.store2 {
    width: 98%;
    max-width: 1460px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.store2-list {
    color: #005426;
    font-family: 'Days One';
    flex-direction: column;
    width: 100%;

    margin-top: 30px;

    height: 650px;
    min-height: 320px;
    overflow-y: auto;

 /*   scrollbar-width: auto;
    scrollbar-color: #005426 #ffffff; */
}



.store2-list::-webkit-scrollbar {
    width: 12px;
}

.store2-list::-webkit-scrollbar-track {
    background: #BFED64;
    border-radius: 10px;
}

.store2-list::-webkit-scrollbar-thumb {
    background-color: #005426;
    border-radius: 10px;
    border: none;
}


.store2-tree-score-cont {
    position: relative;
    width: 80%;
    max-width: 1100px;
}

.store2-tree-score {
    display: flex;
    justify-content: left;
    align-items: center;
}

.store2-tree-txt {
    font-size: 21pt;
    margin-left: 46px;
    margin-top: 18px;
    text-align: center;
}

.store2-tree-icon1 {
    width: 168px;
    height: 58px;
    background-image: url(../Profile/images/applewin1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.store2-tree-icon2 {
    width: 168px;
    height: 58px;
    background-image: url(../Profile/images/applewin2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.store2-tree-icon3 {
    width: 168px;
    height: 58px;
    background-image: url(../Profile/images/applewin3.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.store2-tree-icon4 {
    width: 168px;
    height: 58px;
    background-image: url(../Profile/images/applewin4.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.store2-tree-icon5 {
    width: 168px;
    height: 58px;
    background-image: url(../Profile/images/applewin5.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.store2-tree-icon6 {
    width: 168px;
    height: 58px;
    background-image: url(../Profile/images/applewin6.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

/*----mobile----*/
.store2-tree-score-cont-m {
    position: relative;
    width: 95%;
    max-width: 1100px;
}

.store2-list-m {
    color: #005426;
    font-family: 'Days One';
    flex-direction: column;
    width: 100%;    

    overflow-y: auto;

    scrollbar-width: auto;
    scrollbar-color: #005426 #ffffff;
}

.store2-tree-score-m {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
}
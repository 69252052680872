.temp{
    display: flex;
    justify-content: center;    
    align-items: center;
    color: #005426;
    font-family: 'Days One';
    font-size: 36px;
    height: 792px;
}

.tree-content{
    width: 1100px;
    height: 728px; 

    display: flex;
    align-items: center;  
    justify-content: center;  
}

/*---mobile---*/

.tree-content-m{
    min-width: 540px;
    min-height: 540px; 
    height: calc(100vh - 60px);
    width: 100vw;

    display: flex;
    align-items: center;    
}
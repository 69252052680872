#intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    user-select: none;
}


.regTxt {
    font-family: 'Days One';
    color: white;
    font-size: 19pt;
    margin: 10px 0;
}

.regTxt2 {
    font-family: 'Days One';
    color: white;
    font-size: 19pt;
    margin-bottom: 20px;
}

.regTxt3 {
    font-family: 'Days One';
    color: red;
    font-size: 14pt;
    margin: 10px 0;
}

.regTxt3vt {
    opacity: 1;
}

.regTxt3vf {
    opacity: 0;
}

.regEnabled {
    pointer-events: auto;
}

.regDisabled {
    pointer-events: none;
    opacity: 0.5;
}



.errorTxt {
    font-family: 'Days One';
    color: red;
    font-size: 12pt;
}

.loginTxtBtn {
    cursor: pointer;
    font-family: 'Days One';
    color: white;
    font-size: 14pt;
    /* margin: 40px 0;*/
}

.loginTxtBtn2 {
    color: #005426;
    cursor: pointer;
    font-family: 'Days One';
    font-size: 12pt;
    text-decoration: underline;
    margin-top: -20px;
    margin-bottom: 10px;
}

.loginInput {
    padding: 29px 60px;
    font-size: 24pt;
    border-radius: 50px;
    border: none;
    outline: none;
}

textarea:focus, input:focus {
    outline: none;
}

.marginRegBtn {
    display: flex;

}

.rGap {
    width: 20px;
}

.reg-input {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.reg-input-pc {
    width: 416px;
    height: 72px;
    border-radius: 40px;
}

.reg-input-mobile {
    width: 380px;
    height: 60px;
    margin-bottom: 60px;
    border-radius: 50px;
}

.reg-input-txt {
    color: #005426;
    font-family: 'Days One';
    text-align: center;
    border: none;
}

.reg-input-txt-pc {
    width: 376px;
    font-size: 19px;
    border-radius: 40px;
    padding: 10px;
}

.reg-input-txt-mobile {
    width: 340px;
    font-size: 16pt;

    margin-bottom: 40px;
    border-radius: 40px;
    padding: 20px;
}

*:focus {
    outline: none;
}

.rrating {
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
    font-family: 'Days One';
}

.rrating-pc {
    font-size: 19pt;
}

.rrating-mobile {
    font-size: 22px;
    margin-top: 20px;
}

.rrbtn {
    width: 200px;
    text-align: center;
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 0.1s;
}

.rrbtn:active {
    transform: scale(0.95);
}

.rrbtn-active {
    color: #005426;
    pointer-events: none;
    cursor: none;
}


.rrbtn-passive {
    color: white;
    cursor: pointer;
    pointer-events: auto;
}

.rrbtn-passivef {
    color: white;
    pointer-events: none;
    cursor: none;
}

.rrating-head-btns-gap {
    width: 20px;
    /* background-color:red;*/
    margin-bottom: 20px;
}

.rrating-head-line {
    display: flex;
    width: 420px;
    height: 2px;
    /*background-color:rgb(8, 0, 226);*/
    margin-bottom: 10px;
}

.rrating-head-line-item {
    width: 200px;
    height: 2px;
    background-color: #005426;
}

.rrating-head-line-item0 {
    height: 2px;
    /*background-color: rgb(227, 230, 15);*/
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.ratingCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ratingCard_Bottom {
    width: 100%;
    max-width: 730px;
    height: 1px;
    background-color: #80ad3a;
}

.ratingCard_cont {
    width: 100%;
    max-width: 730px;
    height: 80px;

    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
}

.ratingCard_photo {
    width: 60px;
    height: 100%;
    background-image: url(./images/uicon.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.ratingCard_txt {
    margin-left: 20px;

    display: flex;
    justify-content:center;
    align-items:flex-start;
    flex-direction: column;

}

.ratingCard_txt-t {  
    display: flex;
    justify-content:left;    
}



.ratingCard_txt-score {
    display: flex;    
    align-items:center;
    justify-content:flex-start;
  

    width: 100px;

    margin-right: 28px;
}

.ratingCard_num {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 28px;
    margin-right: 28px;
}

.ratingCard_gap{
    flex: 1;
}
.friendWaiter {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(0%, 0%, 0%, 0.5);
    width: 100%;
    height: 100%;
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;

}

.friendWaiter_window {   
    width: 560px;
    height: 520px;
    margin-top: 85px;
    background-color: white;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.friendWaiter_window_img2_container {
    overflow: hidden;
    width: 100%;
    height: 500px;
}

.friendWaiter_window_exit {

    position: absolute;

    width: 44px;
    height: 44px;
    background-image: url(./images/exit.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;


    margin-left: 100%;
    margin-top: -15px;

}

.friendWaiter_window_title {
    position: absolute;

    width: 95%;
    height: 162px;
    background-image: url(./images/fwi1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.friendWaiter_window_img1 {
    position: absolute;
    top: 180px;

    width: 184px;
    height: 171px;
    background-image: url(./images/fwi2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.friendWaiter_window_img2 {
    width: 100%;
    height: 500px;
    background-image: url(./images/fwi3.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.friendWaiter_window_txt {
    font-size: 24pt;
    font-family: 'Days One';
    color: #005426;
    position: absolute;
    top: 400px;
}



@media only screen and (max-width: 700px) {
    .friendWaiter_window {
        width: 90%;           
    }    
}
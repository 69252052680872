#header {   
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;

    background-color: #52B711;
}

#header_nav {
    height: 60px;
    display: flex;
    justify-content: left;
    align-items:center;
    width: 100%;
}

#header_basement {
    width: 100%;
    height: 2px;
    background-color: #44960F;
}

.header_nav {
    display: flex;
    justify-content: center; 
    align-items:center; 
}

.header_nav_txt {
    font-family: 'Days One';
    font-size: 16px;
    text-transform: uppercase;
}

.header_nav_txt-a {
    color: #005426;
}

.header_nav_txt-p {
    color: white;
}

.header_nav_icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.nav {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.nav1-p {
    background-image: url(./images/r0.png);
}

.nav2-p {
    background-image: url(./images/r2.png);
}

.nav3-p {
    background-image: url(./images/r3.png);
}

.nav4-p {
    background-image: url(./images/r4.png);
}

.nav5-p {
    background-image: url(./images/r5.png);
}

.nav1-a {
    background-image: url(./images/r00.png);
}

.nav2-a {
    background-image: url(./images/r20.png);
}

.nav3-a {
    background-image: url(./images/r30.png);
}

.nav4-a {
    background-image: url(./images/r40.png);
}

.nav5-a {
    background-image: url(./images/r50.png);
}

.nav6-p {
    background-image: url(./images/r1.png);
}
.nav6-a {
    background-image: url(./images/r10.png);
}

.nav7-p {
    background-image: url(./images/r6.png);
}
.nav7-a {
    background-image: url(./images/r60.png);
}




.header_nav_gap {
    width: 20px;
    height: 20px;
   /* background-color: aqua;*/
}

.header_nav_pc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1380px;
    margin: 0 auto;
   /*margin-left: 10%;*/

}

.header_nav_mobile {
    display: none; 
}

.header_nav_mobile_btn{
    width: 50px;
    height: 30px;
    margin-left: 50px;
    
    background-image: url(./images/lines.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;   
}

.header_nav_mobile_menu_txt{
    font-family: 'Days One';
    font-size: 24px;
    width: fit-content;
    height: 100px;
    display: flex;
    align-items:center;
    justify-content: center;
    color: #005426;
    margin-left: 26px;
}

.header_nav_mobile_menu_btn{
    display: flex;
    justify-content: left;
    align-items:center;
    height: 70px;   
    width: 100%;
}

.ml26{
    margin-left: 26px;
}

.header_nav_mobile_menu_btn_r{
   background-color: #80ad3a;
   width: 100%;
   height: 2px;
}

.header_nav_mobile_menu_btn-a{
    background-color: #9dd35e;
}

.header_nav_mobile_menu_btn-p{
    background-color: #ccea81;
}


@media only screen and (max-width: 1200px) {
    .header_nav_pc {
        display: none;
    }

    .header_nav_mobile {
        display: block;
    }   

    #header_basement{
        display: none;
    }

    #header_nav {     
        background-color:unset;      
    }
}

.header_block{
    opacity: .5;
    pointer-events: none;
}


.storeItem {
    background-color: #E0FAAC;
    height: 110px;
    border-radius: 55px;
    margin-bottom: 20px;

    display: flex;
    justify-content: left;
    align-items: center;
}

.storeItem-image {
    width: 100px;
    height: 80px;

    margin-left: 20px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.storeItem-gap {
    flex: 1;
}

.storeItem-name {
   
    font-size: 20px;
    min-width: 150px;
    text-align: center;
}


/*
.storeItem-price-apple {
    width: 60px;
    height: 60px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-image: url(./images/img1.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 80%;
}

.storeItem-price-money {
    width: 60px;
    height: 60px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-image: url(./images/img2.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
}
*/



.storeItem-total {
    display: flex;
    justify-content: center;
    align-items: center;
}

.storeItem-total-num {
    width: 65px;
    height: 50px;

    background-color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 10px;
    margin-right: 10px;

    border-color: #005426;
    border-style: solid;
    border-radius: 10px;
}

/*
.storeItem-total-minus {
    width: 50px;
    height: 50px;

    background-image: url(./images/img3.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
}

.storeItem-total-plus {
    width: 50px;
    height: 50px;

    background-image: url(./images/img4.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
}
*/
.storeItem-buy-btn {
    width: 260px;
    height: 60px;

    background: rgb(108, 23, 58);
    background: linear-gradient(0deg, rgba(108, 23, 58, 1) 0%, rgba(230, 0, 104, 1) 100%);

    border-radius: 30px;
    margin-right: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
}

.storeItem-buy-btn-txt1 {
    margin-right: 10px;
}

.storeItem-buy-btn-txt2 {}

.block {
    pointer-events: none;
    opacity: .5;
}

.hide {
    display: none;
}

.storeItem-buy-my {
    width: 260px;
    height: 60px;
    background-color: white;
    border-radius: 30px;
    margin-right: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.mobileZoom {
    zoom: 0.5;
}

/*------*/
.nest {
    background-image: url(./images/nest.png);
}

.birdhouse {
    background-image: url(./images/birdhouse.png);
}

.dwarf {
    background-image: url(./images/dwarf.png);
}

.swing {
    background-image: url(./images/swing.png);
}

.fountain {
    background-image: url(./images/fountain.png);
}

.lamp {
    background-image: url(./images/lamp.png);
}

.feeder {
    background-image: url(./images/feeder.png);
}

.birdie {
    background-image: url(./images/birdie.png);
}

.flowerbed {
    background-image: url(./images/flowerbed.png);
}

.alcove {
    background-image: url(./images/alcove.png);
}
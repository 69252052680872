.gradient-button {    
    background: rgb(23, 108, 41);
    background: linear-gradient(0deg, rgba(23, 108, 41, 1) 0%, rgba(103, 230, 0, 1) 100%);
    font-family: 'Days One';   
    color: white;
    text-transform: uppercase;
}


.gradient-button-pc{
    padding: 23px 151px ;
    font-size: 19pt;
    border-radius: 40px;
}

.gradient-button-mobile{
    padding: 25px 76px ;
    font-size: 16pt;
    border-radius: 50px;
}

.block{
    opacity: 0.5;
    pointer-events: none;
}


.gradient-button-mini {    
    background: rgb(23, 108, 41);
    background: linear-gradient(0deg, rgba(23, 108, 41, 1) 0%, rgba(103, 230, 0, 1) 100%);
    font-family: 'Days One';   
    color: white;
    text-transform: uppercase;
    text-align: center;

    width:fit-content;
}

.gradient-button-mini-pc{
    padding: 10px 30px;
    border-radius: 25px;    
   
}

.gradient-button-mini-mobile{
   padding: 20px 50px;
   border-radius: 25px ;

}
#intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    user-select: none;
}


.loginTxt {
    font-family: 'Days One';
    color: white;
    font-size: 19pt;
    margin: 16px 0;
}



.errorTxt{
    font-family: 'Days One';
    color: red;
    font-size: 12pt;    
}

.loginTxtBtn {
    cursor: pointer;
    font-family: 'Days One';
    color: white;
    font-size: 14pt;
   /* margin: 40px 0;*/
}

.loginTxtBtn2{
    color: #005426;
    cursor: pointer;
    font-family: 'Days One';
    font-size: 12pt;
    text-decoration: underline;
    margin-top: -20px;
    margin-bottom: 10px;
}

.loginInput {
    padding: 29px 60px;
    font-size: 24pt;
    border-radius: 50px;
    border: none;
    outline: none;
}

textarea:focus, input:focus {
    outline: none;
}

.marginBtn {
    
    margin-top: 20px;
    margin-bottom: 40px;;
}

.nickname-input {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.nickname-input-pc {
    width: 416px;
    height: 72px;
    margin-bottom: 32px;
    border-radius: 40px;
}

.nickname-input-mobile {
    width: 380px;
    height: 60px;
    margin-bottom: 60px;
    border-radius: 50px;
}

.nickname-input-txt {
    color: #005426;
    font-family: 'Days One';
    text-align: center;
    border: none;

  
}

.nickname-input-txt-pc {
    width: 376px;
    font-size: 19px;

    margin-bottom: 32px;
    border-radius: 40px;
    padding: 24px;
}

.nickname-input-txt-mobile {
    width: 340px;
    font-size: 16pt;

    margin-bottom: 40px;
    border-radius: 40px;
    padding: 20px;
}

*:focus {
    outline: none;
}
.Game {
    position: absolute;

    /*background-color: brown;*/
    width: 100%;   
    height: 100%;
   
    user-select: none;
}

.back_btn {
    position: absolute;
    top: 30px;
    left: 12%;
    width: 50px;
    height: 50px;

    background-image: url(./images/back.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.game_container{

    
}

.iframe{
   position: absolute;
   width: 100vw;
   height: 100dvh;
   border: 0px;
   
 
}
.btn {
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 0.1s;
}

.btn:active {
    transform: scale(0.95);
}

.greenBtnIcon {
    background: rgb(23, 108, 41);
    background: linear-gradient(0deg, rgba(23, 108, 41, 1) 0%, rgba(103, 230, 0, 1) 100%);
    font-family: 'Days One';
    color: white; 
}
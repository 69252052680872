.Main {
    height: 100%;
}

.Main-cont {
    /* position: absolute;*/
    width: 100%;
    display: flex;
    justify-content: center;
}

.Main-tree-cont {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    pointer-events: none;
}

.Main-tree-cont-hide {
    /* display: none;*/
    visibility: hidden;
    opacity: 0;
}

.Main-tree-cont-show {
    display: flex;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    visibility: visible;
}

.Main2 {
    position: absolute;
    width: 100%;
    height: 100%;   
    top: 0px;
    left: 0px;

    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 100%;
}

.Main2-header {
    width: 100%;
    height: 60px;    
}

.Main2-content {
    width: 100%;
    height: 100%; 
    
   /* background-color: blue;*/
}

#ver{
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    user-select: none;
}
.friendFinder {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(0%, 0%, 0%, 0.5);
    width: 100%;
    height: 100%;
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.friendFinder_window {
    position: relative;
    margin-top: 85px;
    width: 560px;
    height: 520px;
    background-color: white;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.friendFinder_window_exit {
    position: absolute;
    width: 44px;
    height: 44px;
    background-image: url(./images/exit.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-left: 100%;
    margin-top: -15px;
}

.friendFinder_window_txt {
    font-size: 24px;
    font-family: 'Days One';
    text-transform: uppercase;
    margin-top: 10px;
}

.friendFinder_window_input {
    margin-top: 34px;
    width: 400px;
    height: 68px;
    background-color: #C6C6C6;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: left;
}

.friendFinder_window_input_icon {
    width: 24px;
    height: 24px;
    background-image: url(./images/icon.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-left: 30px;
    margin-right: 10px;
}

.friendFinder_window_input_txt {
    width: 280px;
    font-size: 24px;
    font-family: 'Days One';
    color: white;
    border: none;
    background-color: #C6C6C6;
}

.friendFinder_window_names {
    margin-top: 30px;
    width: 100%;
    height: 80%;
    /*background-color: rgb(0%, 0%, 0%, 0.5);*/
    border-radius: 0 0 30px 30px;
    overflow-y: auto;
}

@media only screen and (max-width: 700px) {
    .friendFinder_window {
        width: 90%;
        height: 80%;
        margin-top: 0px;  
    }
}

.zeroGamers{
    text-align: center;
}
.achievements-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #005426;
    font-family: 'Days One';
    font-size: 36px;

    height: 100%;
}

.achievements {

    /*overflow: hidden;*/

    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.achievements-pc {
   
}

.achievements-mobile {}

.hide {
    display: none;
}


.achievements_header{
    font-size: 30px;
    margin-bottom: 40px;
    text-align: center;
}
.achievements_header_m{
    font-size: 20px;
    margin-bottom: 40px;
    text-align: center;
}

.achievement-page-title {
    font-size: 16px;
    text-transform: uppercase;
}

.achievement-page-title2 {
    margin-top: 40px;
    font-size: 16px;
}

.achievement-page {

    min-width: 400px;
    width: 100%;
    max-width: 820px;

    height: 450px;

    position: relative;


    display: flex;
    justify-content: center;
    align-items: center;

}

.page1 {

    position: absolute;
    text-align: center;
    background-color: white;
    border-radius: 15px;
    width: 320px;
    height: 450px;
    padding-top: 40px;

    -webkit-box-shadow: 0px 0px 8px 7px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 8px 7px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 8px 7px rgba(34, 60, 80, 0.2);
}

.page2 {
    position: absolute;
    width: 70%;
    min-width: 345px;
    max-width: 456px;
    height: 450px;
    background: rgb(247, 255, 217);
    background: linear-gradient(90deg, rgba(247, 255, 217, 1) 50%, rgba(234, 255, 220, 1) 50%);
    border-radius: 15px;
    text-align: center;


    -webkit-box-shadow: 0px 0px 8px 7px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 8px 7px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 8px 7px rgba(34, 60, 80, 0.2);
}

.page3 {
    position: absolute;
    width: 80%;
    min-width: 370px;
    max-width: 592px;
    height: 410px;
    background: rgb(247, 255, 217);
    background: linear-gradient(90deg, rgba(247, 255, 217, 1) 50%, rgba(234, 255, 220, 1) 50%);
    border-radius: 15px;
    text-align: center;

    -webkit-box-shadow: 0px 0px 8px 7px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 8px 7px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 8px 7px rgba(34, 60, 80, 0.2);
}

.page4 {
    position: absolute;

    width: 90%;
    min-width: 395px;
    max-width: 728px;

    height: 370px;
    background: rgb(247, 255, 217);
    background: linear-gradient(90deg, rgba(247, 255, 217, 1) 50%, rgba(234, 255, 220, 1) 50%);
    border-radius: 15px;
    text-align: center;

    -webkit-box-shadow: 0px 0px 8px 7px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 8px 7px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 8px 7px rgba(34, 60, 80, 0.2);
}

.achievement-page-left {

    width: 26px;
    height: 40px;

    background-image: url(./images/bl.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;


    margin-left: 20px;
    margin-right: 20px;
}

.achievement-page-right {
    width: 26px;
    height: 40px;

    background-image: url(./images/br.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;


    margin-left: 20px;
    margin-right: 20px;
}

.achievement-page-content {

    width: 100%;
    height: 100%;

    margin-top: -20px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    overflow: hidden;
    scroll-behavior: smooth;

    transition: 1s;
}

.achievement-page-content-elements{
    font-size: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.achievement-page-content-element-0{
    width: 320px;   
    text-align: center;
    user-select: none;
}
